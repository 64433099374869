import React from "react";
// import "../Css/Blog.css";
import { MetaTags } from "react-meta-tags";
import Zoom from "react-reveal/Zoom";
import Blog1 from "../img/blog1.jpeg";
import Blog2 from "../img/blog2.jpeg";
import Blog3 from "../img/blog3.jpeg";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import Blogdata from "../Components/Blogdata";
import { data } from "jquery";



const Blogs = () => {
  return (
    <>
      {/* ========= Seo start========== */}
      <MetaTags>
        <title>Ocean Delight Beach Resort</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Ocean Delight Beach Resort" />
        <meta property="og:description" content="" />
        <meta name="keywords" content="Ocean Delight Beach Resort" />
        <link rel="canonical" href="https://oceandelight.co.in/" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ========== Blog Banner ==== */}
      <div className="Blog-banner">
        <div className="BlogBanner-Txt">
          <h1 className="d-none">Blogs</h1>
          </div>
          </div>
          {/* <--blog1--> */}

          <div className="container mt-4 mb-5">
            <div className="row g-3 align-items-stretch">
              {Blogdata.map((data)=>(
              <div className="col-12 col-md-6 col-lg-4 p-4 h-100">
                <img src={data.image}  className="mt-3" style={{height:"250px", width:"100%", objectFit:"cover",borderRadius:"16px" }} alt="Blog1"  />
                  <h4 className="mt-2">{data.title}</h4>
                  <p className="Poppins mt-2" style={{textAlign:'justify'}}>{data.desc1.length>150?data.desc1.slice(0,150)+' ....':data.desc1}
                  </p>
                  <Link className="#" to={data?.slug} >Read More</Link>
              </div>
          ))}
          {/* <div className="col-12 col-md-6 col-lg-4 p-3">
                <img src={Blog2} className="mt-3" style={{height:"250px", width:"100%", objectFit:"cover",borderRadius:"16px" }} alt="Blog2" />
                  <h4 className="mt-2">Title of the Blog</h4>
                  <p className="Poppins mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto veritatis adipisci velit nam nobis id, quas saepe reiciendis esse facilis libero consequatur sed cupiditate totam voluptatem nisi inventore ipsa eligendi.
                  </p>
                  <NavLink className="#" to={data.slug}>Read More</NavLink>
                </div>
              
              <div className="col-12 col-md-6 col-lg-4 p-3">
                <img src={Blog3} className="mt-3" style={{height:"250px", width:"100%", objectFit:"cover",borderRadius:"16px" }} alt="Blog3" />
                  <h4 className="mt-2">Title of the Blog</h4>
                  <p className="Poppins mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto veritatis adipisci velit nam nobis id, quas saepe reiciendis esse facilis libero consequatur sed cupiditate totam voluptatem nisi inventore ipsa eligendi.
                  </p>
                  <NavLink className="#" to={data.slug}>Read More</NavLink>
                </div>

                <div className="col-12 col-md-6 col-lg-4 p-3">
                <img src={Blog3} className="mt-3" style={{height:"250px", width:"100%", objectFit:"cover",borderRadius:"16px" }} alt="Blog4" />
                  <h4 className="mt-2">Title of the Blog</h4>
                  <p className="Poppins mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto veritatis adipisci velit nam nobis id, quas saepe reiciendis esse facilis libero consequatur sed cupiditate totam voluptatem nisi inventore ipsa eligendi.
                  </p>
                  <NavLink className="#" to={data.slug}>Read More</NavLink>
                </div>

                <div className="col-12 col-md-6 col-lg-4 p-3">
                <img src={Blog2} className="mt-3" style={{height:"250px", width:"100%", objectFit:"cover",borderRadius:"16px" }} alt="Blog5" />
                  <h4 className="mt-2">Title of the Blog</h4>
                  <p className="Poppins mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto veritatis adipisci velit nam nobis id, quas saepe reiciendis esse facilis libero consequatur sed cupiditate totam voluptatem nisi inventore ipsa eligendi.
                  </p>
                  <NavLink className="#" to={data.slug}>Read More</NavLink>
                </div>

                <div className="col-12 col-md-6 col-lg-4 p-3">
                <img src={Blog1} className="mt-3" style={{height:"250px", width:"100%", objectFit:"cover",borderRadius:"16px" }} alt="Blog6" />
                  <h4 className="mt-2">Title of the Blog</h4>
                  <p className="Poppins mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto veritatis adipisci velit nam nobis id, quas saepe reiciendis esse facilis libero consequatur sed cupiditate totam voluptatem nisi inventore ipsa eligendi.
                  </p>
                  <NavLink className="#" to={data.slug}>Read More</NavLink>
                </div> */}
              </div>
            </div>
          

          


          
{/* <div class="container mt-4 mb-4">
    <h1 class="text-center mb-4">Blog Posts</h1>
    <div class="card"> 
      <div class="card-body">
           <div class="row">
          <div class="col-md-4">
            <div class="card mb-4">
                <img src="https://via.placeholder.com/600x300" class="card-img-top" alt="#"/>
                <div class="card-body">
                    <h5 class="card-title">Blog Title 1</h5>
                    <p class="card-text">This is a short summary of the first blog post.</p>
                    <a href="#" class="btn btn-primary">Read More</a>
                </div>
            </div>
        </div> */}
        
          {/* <--blog2--> */}
        {/* <div class="col-md-4">
            <div class="card card-blog">
                <img src="https://via.placeholder.com/600x300" class="card-img-top" alt="#"/>
                <div class="card-body">
                    <h5 class="card-title">Blog Title 2</h5>
                    <p class="card-text">This is a short summary of the second blog post.</p>
                    <a href="#" class="btn btn-primary">Read More</a>
            </div>
          </div>
        </div>
                 {/* <--blog3--> */}
       {/* <div class="col-md-4">
        <div class="card card-body">
            <img src= "https://via.placeholder.com/600x300" class="card-img-top" alt="#"/>
            <div class="card-body">
                <h5 class="card-title">Blog Title 3</h5>
                <p class="card-text">This is a short summary of the third blog post.</p>
                <a href="#" class="btn btn-primary">Read More</a>
            </div>
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
       */} 


          
      <Footer/>
    </>
  );
};

export default Blogs;