import Blog1 from '../img/blog1.jpeg';
import Blog2 from "../img/blog2.jpeg";
import Blog3 from "../img/blog3.jpeg";


const Blogdata=[
    {
        'title':'Unwind and Relax: Discover the Ultimate Beach Resort Getaway',
        'slug':'/unwind-and-relax-discover-the-ultimate-beach-resort-gateway',
        'image':[Blog1],
        'imageone':[Blog2],
        'imagetwo':[Blog3],
        'date':'May 30, 2023',
        'time':'3:32 PM',
        'authorname':'Rhea Mathews',
        'desc1':'Looking for a perfect tropical getaway? Look no further than our beachfront resort, where coconut trees sway in the gentle breeze, the sunset view is breath-taking, birds chirping, and the white sand stretches as far as the eye can see.',
        'desc2':'At our resort, we offer everything you need for a comfortable and relaxing stay. Embrace a blissful retreat in our thoughtfully designed spacious rooms, meticulously crafted with your comfort as our top priority. Every room is meticulously furnished with all the amenities necessary for a delightful stay, including snug beds, enchanting room lighting, expansive bathrooms, round-the-clock hot water supply, and a flat-screen TV. Additionally, our top-floor balconies offer a mesmerizing sunset view, providing the perfect setting to unwind and soak in the beauty of the surroundings. In addition to the luxurious accommodations, our resort is an excellent choice for long workcations. With its ideal location and range of amenities, it caters to your professional needs while providing a tranquil setting.',
        'desc3':'But the real star of the show at our resort is the Bhogwe beach. You can spend your days lounging on the sand, taking a dip in the crystal-clear waters, or enjoying a variety of water sports and activities.',
        'titleone':'',
        'desc22':'And, of course, no tropical getaway would be complete without delicious food. At our resort, we specialize in Malvani cuisine, which is famous for its bold flavors and fresh seafood. Our expert chefs use only the freshest ingredients to create mouthwatering dishes that are sure to satisfy even the most discerning palates. The resort’s restaurant serves up a variety of traditional Malvani, tandoori, Chinese dishes, to including seafood curries, spicy masalas, and coconut-based gravies. Whether you’re a meat lover or a vegetarian, there’s something on the menu for everyone. For those who savor non-vegetarian delights, exploring the delectable flavors of Chicken Kentucky, Chicken Chilli, and Chicken Biryani is an absolute must. ',
        'titletwo':'',
        'desc33':'As the sun begins to set, make your way to the beach to witness one of the most beautiful sights you’ll ever see. The orange and pink hues of the sunset reflect off the water, creating a magical atmosphere that’s both serene and breathtaking. ',
        'titlethree':'',
        'desc4':'So what are you waiting for? Book your stay at our beachfront resort today and experience the ultimate tropical vacation. With coconut trees swaying, a stunning sunset view, white sand beaches, delicious Malvani food, and comfortable, spacious rooms, you’re sure to have a memorable and relaxing stay. ',
        'descone':'',
        'desctwo':'',
        'title1':'There are several nearby attractions and places you can explore. Here are a few suggestions:',
        'span1':'Tarkarli Beach:',
        'titlefour':'',
        'desc44':'Located approximately 12 km from the resort, known for water sports.',
         'descthree':'',
         'descfour':'',
         'descfive':'',
        'span2':'Sindhudurg Fort:',
        'desc5':'A historical fort built by Shivaji Maharaj, about 17 km away.',
        'descsix':'',
        'descseven':'',
        'desceight': '',
        'descnine':'',
        'span3':'Devbagh Beach:',
        'desc6':'Located around 22 kilometers from the resort, Devbagh Beach is known for its serene and secluded ambiance. The beach is surrounded by casuarina trees and offers opportunities for water sports, dolphin spotting, and leisurely walks along the shoreline..',
        'span4' :'Tsunami Island:',
        'desc7':' Situated near Devbagh Beach, Tsunami Island is a small, picturesque island accessible by boat. It offers various water sports activities like jet skiing, banana boat rides, and parasailing. You can spend a fun-filled day here.',
        'desc8':'Experience a boat ride at an additional cost near the villa and explore the nearby Tsunami Island and Nivati Island. Enjoy the opportunity to witness dolphins in the midst of the sea, as well as visit the lighthouse and the Devbhag Sangam.',
        'spanone':'',
        'desc9':'These are just a few suggestions for places to visit near Bhogwe Beach and the Ocean Delight Resort. The region is known for its scenic beauty, historical sites, and coastal attractions, so there are plenty of options to explore and enjoy during your stay.',
        'spantwo':'',
        'descten':'',
        'spanthree':'',
        'descel':'',
        'title2':'Ways to reach Ocean Delight Resort',
        'desc10':'By Air: If you are arriving by air, the nearest airport is Dabolim Airport in Goa. From there, you can hire a taxi or take a pre-booked car to reach the resort. The journey from the airport to the resort usually takes around 2-3 hours, depending on the traffic and road conditions',
        'desc11':'By Train: The closest railway station to Ocean Delight Resort is Sawantwadi Road Railway Station. Upon reaching the station, you can take a taxi or use local transportation to reach the resort. The travel time from the railway station to the resort is approximately 30-40 minutes',
        'desc12':'By Road: If you prefer traveling by road, you can drive to Ocean Delight Resort. Bhogwe Beach is well-connected by road, and you can use GPS navigation or follow the signboards to reach the resort. The exact directions will depend on your starting location, so it’s advisable to plan your route in advance',
        'desc13':'Please note that its always recommended to check the road conditions and weather updates before starting your journey to ensure a smooth and safe travel experience.',
    },

// {
//     'title':'A Paradise Unveiled',
//     'slug':'/a-paradise-unveiled',
//     'image':[Blog1],
//     'imageone':[Blog2],
//     'imagetwo':[Blog3],
//     'date':'May 30, 2023',
//     'time':'3:32 PM',
//     'authorname':'Rhea Mathews',
//     'desc1':'Nestled along the sun-kissed shores of one of the most pristine coastlines, Ocean Delight Beach Resort is a hidden gem that offers an unparalleled escape into tropical bliss. With its breathtaking Sea views, luxurious amenities, and a warm, welcoming ambiance, this resort has become a haven for travellers seeking tranquillity, adventure, and unforgettable memories. In this blog, we will take a deep dive into everything that makes Ocean Delight Beach Resort a must-visit destination, from its exquisite accommodations to the wide array of activities that await guests.',
//     'desc2':'Located on a secluded stretch of white sandy beach, Ocean Delight Beach Resort is a sanctuary where nature and luxury coexist harmoniously. Surrounded by lush coconut trees and the gentle whispers of ocean waves, the resort offers a serene retreat from the hustle and bustle of everyday life. Whether you’re planning a romantic getaway, a family vacation, or a solo adventure, this resort caters to every traveller’s needs.',
//     'desc3':'',
//     'titleone':'Accommodations That Redefine Comfort',
//     'desc22':'Ocean Delight Beach Resort boasts an array of accommodations designed to provide the ultimate in comfort and style. From beachfront luxurious private rooms nestled amidst coconut farms, each unit is thoughtfully crafted to enhance your stay. The interiors feature modern decor infused with coastal charm, complete with plush bedding, spacious living areas, and state-of-the-art amenities. Floor-to-ceiling windows invite natural light to flood in, offering spectacular views of the Arabian sea.',
//     'titletwo':'Culinary Delights',
//     'desc33':'A stay at Ocean Delight Beach Resort is incomplete without savouring its culinary offerings. The resort’s dining venues are a feast for the senses, providing a menu that celebrates the flavours of the sea and beyond.',
//     'titlethree':'Open Sea View Restaurant : ',
//     'desc4':'Known for its fresh seafood and innovative dishes, they offer a fine dining experience with panoramic ocean views.',
//     'descone':'As the name suggests, this beachfront venue is ideal for watching the sun dip below the horizon while enjoying grilled specialties and tropical drinks.',
//     'desctwo':'The resort also offers private dining experiences, where guests can enjoy a romantic candlelight dinner on the beach or an Open Sky Dining experiences with bonfire.',
//     'title1':'Activities and Adventures',
//     'span1':'Ocean Delight Beach Resort is a playground for both relaxation and adventure enthusiasts. The resort’s activity calendar is brimming with options to keep guests engaged and entertained.',
//     'titlefour':'Water Sports and Ocean Exploration',
//     'desc44':'The resort’s prime beachfront location makes it a hub for water-based activities Guests can :',
//     'descthree':'Snorkel or scuba dive to explore vibrant coral reefs teeming with marine life.',
//     'descfour':'Embark on a deep-sea fishing expedition',
//     'descfive':'Take deep Scuba lessons with experienced instructors.',
//     'span2':'Cultural Experiences and Excursions',
//     'desc5':'Immerse yourself in the local culture with activities such as:',
//     'descsix':'Traditional cooking classes, where you can learn to prepare regional delicacies.',
//     'descseven':'Guided tours to nearby villages, offering a glimpse into the local way of life.',
//     'desceight':'Nightly cultural performances featuring music and dance native to the area.',
//     'descnine':'For explorers, the resort organizes day trips to nearby attractions such as nature reserves, waterfalls, and historic landmarks.',
    
//     'span3':'Family-Friendly Fun',
//     'desc6':'Ocean Delight Beach Resort is a family-friendly destination with plenty to keep younger guests entertained. Kids and adults can enjoy beach games, swimming, fishing and exploring nearby attractions. The tranquil environment is perfect for bonding, while spacious accommodations and delicious cuisine ensure comfort and enjoyment for the whole family.',
//     'span4' :'Planning Your Stay',
//     'desc7':'Getting to Ocean Delight Beach Resort is an adventure in itself. The resort is easily accessible via a boat ride or pickup transfer from the Chipi or Goa airport. Upon arrival, guests are greeted with a refreshing welcome drink and the warm hospitality of the resort staff.',
//     'desc8':'To make the most of your visit, consider the following tips:',
//     'spanone':'Book Early:',
//     'desc9':'The resort’s popularity means that accommodations fill up quickly, especially during peak seasons.',
//     'spantwo':'Pack Smart:',
//     'descten':'Don’t forget essentials like sunscreen, swimwear, and comfortable footwear for exploring.',
//     'spanthree':'Customize Your Experience:',
//     'descel':'Book in advance to take advantage and plan personalized activities and excursions.',
//     'title2':'Creating Memories to Last a Lifetime',
//     'desc10':'Whether you’re exploring the vibrant underwater world, every moment at Ocean Delight Beach Resort is designed to leave a lasting impression. The combination of natural beauty, exceptional service, and a wide range of experiences makes this destination truly unforgettable.',
//     'desc11':'In conclusion, Ocean Delight Beach Resort is more than just a place to stay – it’s a destination where dreams come to life. Whether you’re seeking relaxation, adventure, or a bit of both, this resort promises an experience that will linger in your heart long after you’ve returned home. So, pack your bags, leave your worries behind, and let the ocean’s delights sweep you away to a world of unparalleled beauty and serenity.',
//     'desc12':'',
//     'desc13':'',
// }
{
    'title':'A Paradise Unveiled',
        'slug':'/a-paradise-unveiled',
        'image':[Blog1],
        'imageone':[Blog2],
        'imagetwo':[Blog3],


    // {
    //     'title':'A Paradise Unveiled',
    //     'slug':'/a-paradise-unveiled',
    //     'image':[Blog1],
    //     'imageone':[Blog2],
    //     'imagetwo':[Blog3],

        'date':'',
        'time':'',
        'authorname':'Rhea Mathews',
        'desc1':'Nestled along the sun-kissed shores of one of the most pristine coastlines, Ocean Delight Beach Resort is a hidden gem that offers an unparalleled escape into tropical bliss. With its breathtaking Sea views, luxurious amenities, and a warm, welcoming ambiance, this resort has become a haven for travellers seeking tranquillity, adventure, and unforgettable memories. In this blog, we will take a deep dive into everything that makes Ocean Delight Beach Resort a must-visit destination, from its exquisite accommodations to the wide array of activities that await guests.',
        'desc2':'Located on a secluded stretch of white sandy beach, Ocean Delight Beach Resort is a sanctuary where nature and luxury coexist harmoniously. Surrounded by lush coconut trees and the gentle whispers of ocean waves, the resort offers a serene retreat from the hustle and bustle of everyday life. Whether you’re planning a romantic getaway, a family vacation, or a solo adventure, this resort caters to every traveller’s needs.',
        'title1':'Accommodations That Redefine Comfort',
        'desc3':'Ocean Delight Beach Resort boasts an array of accommodations designed to provide the ultimate in comfort and style. From beachfront luxurious private rooms nestled amidst coconut farms, each unit is thoughtfully crafted to enhance your stay. The interiors feature modern decor infused with coastal charm, complete with plush bedding, spacious living areas, and state-of-the-art amenities. Floor-to-ceiling windows invite natural light to flood in, offering spectacular views of the Arabian sea.',
        'title2':'Culinary Delights',
        'desc44':'A stay at Ocean Delight Beach Resort is incomplete without savouring its culinary offerings. The resort’s dining venues are a feast for the senses, providing a menu that celebrates the flavours of the sea and beyond.',
        'span5':'Open Sea View Restaurant :',
        'desc5': 'Known for its fresh seafood and innovative dishes, they offer a fine dining experience with panoramic ocean views.',
        'desc6':'As the name suggests, this beachfront venue is ideal for watching the sun dip below the horizon while enjoying grilled specialties and tropical drinks.',
        'desc7':'The resort also offers private dining experiences, where guests can enjoy a romantic candlelight dinner on the beach or an Open Sky Dining experiences with bonfire.',
        'title3':'Activities and Adventures',
        'desc8':'Ocean Delight Beach Resort is a playground for both relaxation and adventure enthusiasts. The resort’s activity calendar is brimming with options to keep guests engaged and entertained.',
        'title4':'Water Sports and Ocean Exploration',
        'span6':'The resort’s prime beachfront location makes it a hub for water-based activities Guests can :',
        'desc9':'Snorkel or scuba dive to explore vibrant coral reefs teeming with marine life.',
        'desc10':'Embark on a deep-sea fishing expedition',
        'desc11':'Take deep Scuba lessons with experienced instructors.',
        'title5':'Cultural Experiences and Excursions',
        'desc12':'Immerse yourself in the local culture with activities such as:',
        'desc13':'Traditional cooking classes, where you can learn to prepare regional delicacies.',
        'desc14':'Guided tours to nearby villages, offering a glimpse into the local way of life.',
        'desc15':'Nightly cultural performances featuring music and dance native to the area.',
        'desc16':'For explorers, the resort organizes day trips to nearby attractions such as nature reserves, waterfalls, and historic landmarks.',
        'title6':'Family-Friendly Fun',
        'desc17':'Ocean Delight Beach Resort is a family-friendly destination with plenty to keep younger guests entertained. Kids and adults can enjoy beach games, swimming, fishing and exploring nearby attractions. The tranquil environment is perfect for bonding, while spacious accommodations and delicious cuisine ensure comfort and enjoyment for the whole family.',
        'title7':'Planning Your Stay',
        'desc18':'Getting to Ocean Delight Beach Resort is an adventure in itself. The resort is easily accessible via a boat ride or pickup transfer from the Chipi or Goa airport. Upon arrival, guests are greeted with a refreshing welcome drink and the warm hospitality of the resort staff.',
        'desc19':'To make the most of your visit, consider the following tips :',
        'span7':'Book Early :',
        'desc20':'The resort’s popularity means that accommodations fill up quickly, especially during peak seasons.',
        'span8':'Pack Smart :',
        'desc21':'Don’t forget essentials like sunscreen, swimwear, and comfortable footwear for exploring.',
        'span9':'Customize Your Experience :',
        'desc22':'Book in advance to take advantage and plan personalized activities and excursions : For explorers, the resort organizes day trips to nearby attractions such as nature reserves, waterfalls, and historic landmarks. Ocean Delight Beach Resort is a family-friendly destination with plenty to keep younger guests entertained. Kids and adults can enjoy beach games, swimming, fishing and exploring nearby attractions. The tranquil environment is perfect for bonding, while spacious accommodations and delicious cuisine ensure comfort and enjoyment for the whole family. ',
        'title8':'Creating Memories to Last a Lifetime',
        'desc33':'Whether you’re exploring the vibrant underwater world, every moment at Ocean Delight Beach Resort is designed to leave a lasting impression. The combination of natural beauty, exceptional service, and a wide range of experiences makes this destination truly unforgettable.',
        'desc4':' To make the most of your visit, consider the following tips : Book Early : The resort’s popularity means that accommodations fill up quickly, especially during peak seasons. Pack Smart : Don’t forget essentials like sunscreen, swimwear, and comfortable footwear for exploring.In conclusion, Ocean Delight Beach Resort is more than just a place to stay – it’s a destination where dreams come to life.Whether you’re seeking relaxation, adventure, or a bit of both, this resort promises an experience that will linger in your heart long after you’ve returned home. So, pack your bags, leave your worries behind, and let the ocean’s delights sweep you away to a world of unparalleled beauty and serenity.',
    }
]



export default Blogdata;










    // {
    //     "id": 1,
    //     "title": "Blog Post 1",
    //     "content": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem atque amet aspernatur alias, aperiam voluptate iste nobis facilis illo ea impedit tempore quod mollitia, soluta enim. Nobis consequuntur vero sed?",
    //     "image":Blog2,
    //     "slug":"/blog-post-1"
    // },
    // {
    //     "id": 2,
    //     "title": "Blog Post 2",
    //     "content": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem atque amet aspernatur alias, aperiam voluptate iste nobis facilis illo ea impedit tempore quod mollitia, soluta enim. Nobis consequuntur vero sed?",
    //     "image":Blog3,
    //     "slug":"/blog-post-2"
    // },
    // {
    //     "id": 3,
    //     "title": "Blog Post 3",
    //     "content": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem atque amet aspernatur alias, aperiam voluptate iste nobis facilis illo ea impedit tempore quod mollitia, soluta enim. Nobis consequuntur vero sed?",
    //     "image":Blog1,
    //     "slug":"/blog-post-3"
    // },
    // {
    //     "id": 4,
    //     "title": "Blog Post 4",
    //     "content": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem atque amet aspernatur alias, aperiam voluptate iste nobis facilis illo ea impedit tempore quod mollitia, soluta enim. Nobis consequuntur vero sed?",
    //     "image":Blog2,
    //     "slug":"/blog-post-4"
    // },
    // {
    //     "id": 5,
    //     "title": "Blog Post 5",
    //     "content": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem atque amet aspernatur alias, aperiam voluptate iste nobis facilis illo ea impedit tempore quod mollitia, soluta enim. Nobis consequuntur vero sed?",
    //     "image":Blog3,
    //     "slug":"/blog-post-5"
    // },
    // {
    //     "id": 6,
    //     "title": "Blog Post 6",
    //     "content": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem atque amet aspernatur alias, aperiam voluptate iste nobis facilis illo ea impedit tempore quod mollitia, soluta enim. Nobis consequuntur vero sed?",
    //     "image":Blog1,
    //     "slug":"/blog-post-6"
    // },
    


