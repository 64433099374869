import React from "react";
import "../Css/Blog.css";
import { MetaTags } from "react-meta-tags";
import Zoom from "react-reveal/Zoom";
import Blog1 from "../img/blog1.jpeg";
import Blog2 from "../img/blog2.jpeg";
import Blog3 from "../img/blog3.jpeg";
import Footer from "../Components/Footer";
import { useLocation } from "react-router-dom";
import Blogdata from "../Components/Blogdata";

const Blog = () => {
  const search=useLocation();
  const path = search.pathname;
  console.log("path",path);

  const finalData=Blogdata.find((elem)=> elem.slug==path)
  console.log(finalData);
  
  
  return (
    <>
      {/* ========= Seo start========== */}
      <MetaTags>
        <title>Ocean Delight Beach Resort</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Ocean Delight Beach Resort" />
        <meta property="og:description" content="" />
        <meta name="keywords" content="Ocean Delight Beach Resort" />
        <link rel="canonical" href="https://oceandelight.co.in/" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ========== Blog Banner ==== */}
      <div className="Blog-banner">
        <div className="BlogBanner-Txt">
          <h1 className="d-none">Blogs</h1>
          <Zoom>
            <h2>Blogs</h2>
          </Zoom>
          <p>
            Ocean Delight Beach Resort in bhogwe, a beautiful beach resort in a
            tropical paradise.
          </p>
        </div>
      </div>
      {/* ============== Blog Detail Section ========== */}
      <section className="room-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="blog-head">
                <h2>
                  {finalData && finalData.title}
                  {/* “Unwind and Relax: Discover the Ultimate Beach Resort Getaway” */}
                </h2>
                <h6 className="mt-3"> 
                  {/* Rhea Mathews  */} {finalData && finalData.authorname}
                  <span> <i class="fa fa-birthday-cake" aria-hidden="true"></i>
                 {/* May 30, 2023 */}{finalData && finalData.date}
                 </span> 
                 <span> <i class="fa fa-clock-o" aria-hidden="true"></i>
                  {/* 3:32 pm */} {finalData && finalData.time}
                  </span></h6>

                <h4 className="mt-3">
                  Rhea Mathews 
                  {/* {finalData && finalData.authorname} */}
                  </h4>
                <h5>Post writers</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className=
              "blog-img1" >
              
                <img
                  src={finalData && finalData.image}
                  alt="Blogs"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-12">
              <div className="BlogDesc1">
                <div className="blogTxt1">
                  <p>
                    {/* Looking for a perfect tropical getaway? Look no further than
                    our beachfront resort, where coconut trees sway in the
                    gentle breeze, the sunset view is breath-taking, birds
                    chirping, and the white sand stretches as far as the eye can
                    see. */}  {finalData && finalData.desc1}
                  </p>
                  <p>
                    {/* At our resort, we offer everything you need for a
                    comfortable and relaxing stay. Embrace a blissful retreat in
                    our thoughtfully designed spacious rooms, meticulously
                    crafted with your comfort as our top priority. Every room is
                    meticulously furnished with all the amenities necessary for
                    a delightful stay, including snug beds, enchanting room
                    lighting, expansive bathrooms, round-the-clock hot water
                    supply, and a flat-screen TV. Additionally, our top-floor
                    balconies offer a mesmerizing sunset view, providing the
                    perfect setting to unwind and soak in the beauty of the
                    surroundings. In addition to the luxurious accommodations,
                    our resort is an excellent choice for long workcations. With
                    its ideal location and range of amenities, it caters to your
                    professional needs while providing a tranquil setting. */}
                    {finalData && finalData.desc2}
                  </p>
                  <p>
                    {/* But the real star of the show at our resort is the Bhogwe
                    beach. You can spend your days lounging on the sand, taking
                    a dip in the crystal-clear waters, or enjoying a variety of
                    water sports and activities. */}
                    {finalData && finalData.desc3}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
            <div className="row">
                <div className="col-lg-4 col-md-6">
                <div className= 
                 "blog-img2">
                 
                <img
                  src={finalData && finalData.imageone}
                  alt="Blogs"/>
              </div>
                </div>
                <div className="col-lg-8 col-md-6">
                <div className="BlogDesc2">
                <div className="blogTxt2">
                  <p>
                  {/* And, of course, no tropical getaway would be complete without delicious food. At our resort, we specialize in Malvani cuisine, which is famous for its bold flavors and fresh seafood. Our expert chefs use only the freshest ingredients to create mouthwatering dishes that are sure to satisfy even the most discerning palates. The resort’s restaurant serves up a variety of traditional Malvani, tandoori, Chinese dishes, to including seafood curries, spicy masalas, and coconut-based gravies. Whether you’re a meat lover or a vegetarian, there’s something on the menu for everyone. For those who savor non-vegetarian delights, exploring the delectable flavors of Chicken Kentucky, Chicken Chilli, and Chicken Biryani is an absolute must. */}
                  {finalData && finalData.desc22}
                  </p>
                  <p>
                  {/* As the sun begins to set, make your way to the beach to witness one of the most beautiful sights you’ll ever see. The orange and pink hues of the sunset reflect off the water, creating a magical atmosphere that’s both serene and breathtaking. */}
                  {finalData && finalData.desc33}
                  </p>
                  <p>
                  {/* So what are you waiting for? Book your stay at our beachfront resort today and experience the ultimate tropical vacation. With coconut trees swaying, a stunning sunset view, white sand beaches, delicious Malvani food, and comfortable, spacious rooms, you’re sure to have a memorable and relaxing stay. */}
                  {finalData && finalData.desc4}
                  </p>
                </div>
              </div>
                </div>
            </div>
        </div>
        <div className="container mt-5">
          <div className="row">
          <div className="blog-head2">
            <h3>
              {/* There are several nearby attractions and places you can explore. Here are a few suggestions: */}
              {finalData && finalData.title1}
            </h3>
            </div>
            <div className="col-12">
            <div className=
            "blog-img3">
                <img
                  src={finalData && finalData.imagetwo}
                  alt="Blogs"
                />
              </div>
            </div>
            <div className="col-12">
              <div className="BlogDesc3">
                <div className="blogTxt3">
                  <p>
                  <span>
                    {/* Tarkarli Beach:  */}
                    {finalData && finalData.span1}
                  </span>
                   {/* Located approximately 12 kilometers from resort, Tarkarli Beach is a popular tourist spot known for its pristine waters, white sand, and water sports activities. You can indulge in snorkeling, scuba diving, and boat rides here. */}
                   {finalData && finalData.desc44}
                  </p>
                  <p>
                 <span>
                  {/* Sindhudurg Fort:  */}
                  {finalData && finalData.span2}
                  </span> 
                  {/* Situated around 17 kilometers from the resort, Sindhudurg Fort is a historical landmark built by Shivaji Maharaj. The fort is surrounded by the Arabian Sea, and you can explore its impressive architecture and enjoy panoramic views of the coastline. */}
                  {finalData && finalData.desc5}
                  </p>
                  <p>
                  <span> 
                    {/* Devbagh Beach:  */}
                    {finalData && finalData.span3}
                  </span> 
                  {/* Located around 22 kilometers from the resort, Devbagh Beach is known for its serene and secluded ambiance. The beach is surrounded by casuarina trees and offers opportunities for water sports, dolphin spotting, and leisurely walks along the shoreline. */}
                  {finalData && finalData.desc6}
                  </p>
                  <p><span>
                    {/* Tsunami Island: */}
                    {finalData && finalData.span4}
                     </span> 
                     {finalData && finalData.desc7}
                   {/* Situated near Devbagh Beach, Tsunami Island is a small, picturesque island accessible by boat. It offers various water sports activities like jet skiing, banana boat rides, and parasailing. You can spend a fun-filled day here.*/}
                    </p> 
                  <p>
                    {/* Experience a boat ride at an additional cost near the villa and explore the nearby Tsunami Island and Nivati Island. Enjoy the opportunity to witness dolphins in the midst of the sea, as well as visit the lighthouse and the Devbhag Sangam.
                     */} {finalData && finalData.desc8}
                     </p>
                  <p>
                    {/* These are just a few suggestions for places to visit near Bhogwe Beach and the Ocean Delight Resort. The region is known for its scenic beauty, historical sites, and coastal attractions, so there are plenty of options to explore and enjoy during your stay. */}
                    {finalData && finalData.desc9}
                    </p>

                  <small>
                    {/* Ways to reach Ocean Delight Resort : */}
                    {finalData && finalData.title2}
                    </small>
                  <p>

                    {/* By Air: If you are arriving by air, the nearest airport is Dabolim Airport in Goa. From there, you can hire a taxi or take a pre-booked car to reach the resort. The journey from the airport to the resort usually takes around 2-3 hours, depending on the traffic and road conditions. */}
                    {finalData && finalData.desc10}
                  </p>
                  <p>
                    {/* By Train: The closest railway station to Ocean Delight Resort is Sawantwadi Road Railway Station. Upon reaching the station, you can take a taxi or use local transportation to reach the resort. The travel time from the railway station to the resort is approximately 30-40 minutes. */}
                    {finalData && finalData.desc11}
                    </p>
                  <p>
                    {/* By Road: If you prefer traveling by road, you can drive to Ocean Delight Resort. Bhogwe Beach is well-connected by road, and you can use GPS navigation or follow the signboards to reach the resort. The exact directions will depend on your starting location, so it’s advisable to plan your route in advance. */}
                    {finalData && finalData.desc12}
                    </p>
                  <p>
                    {/* Please note that it's always recommended to check the road conditions and weather updates before starting your journey to ensure a smooth and safe travel experience. */}
                    {finalData && finalData.desc13}
                    </p>
                </div>
              </div>
            </div>
            </div>
            </div>

              {/* <div className="room-section">
                <div className="container">
              <div className="row">
                <div className="col md-12">
                  <div className="blog-head3"> */}
                    {/* <h2>
                      "A Paradise Unveiled" {finalData && finalData.title}
                      </h2>
                    <h6 className="mt-3">
                      Rhea Mathews  {finalData && finalData.authorname}
                       <span> <i class="fa fa-birthday-cake" aria-hidden="true"></i></span> <span> <i class="fa fa-clock-o" aria-hidden="true"></i></span></h6>
                    <h4 className="mt-3">
                      Rhea Mathews 
                      {finalData && finalData.authorname}
                    </h4>
                    <h5>Post writers</h5> */}
                  {/* </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                  </div>
                </div>
              </div>
              <div className="container mt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="BlogDesc2">
                      <div className="blogTxt2">
                        <p> */}
                        {/* Nestled along the sun-kissed shores of one of the most pristine coastlines, Ocean Delight Beach Resort is a hidden gem that offers an unparalleled escape into tropical bliss. With its breathtaking Sea views, luxurious amenities, and a warm, welcoming ambiance, this resort has become a haven for travellers seeking tranquillity, adventure, and unforgettable memories. In this blog, we will take a deep dive into everything that makes Ocean Delight Beach Resort a must-visit destination, from its exquisite accommodations to the wide array of activities that await guests. */}
                        {/* {finalData && finalData.desc1}
                        </p>
                        <p> */}
                          {/* Located on a secluded stretch of white sandy beach, Ocean Delight Beach Resort is a sanctuary where nature and luxury coexist harmoniously. Surrounded by lush coconut trees and the gentle whispers of ocean waves, the resort offers a serene retreat from the hustle and bustle of everyday life. Whether you’re planning a romantic getaway, a family vacation, or a solo adventure, this resort caters to every traveller’s needs. */}
                          {/* {finalData && finalData.desc2}
                          </p>
                        </div>
                        </div>
                       </div>
                     </div>
                   </div> */}

                   {/* <div className="container mt-5">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className=
                        "blog-img1"> */}
                        
                        {/* <img
                         src={finalData && finalData.image}
                         alt="Blogs"
                        /> */}
                        {/* </div>
                        </div>
                        
                          <div className="container mt-5">
                            <div className="row">
                              <div className="blog-head4"> */}
                                {/* <h3> */}
                                  {/* "Accommodations That Redefine Comfort" */}
                                  {/* {finalData && finalData.titleone}
                                  </h3>
                                <p> */}
                                  {/* Ocean Delight Beach Resort boasts an array of accommodations designed to provide the ultimate in comfort and style. From beachfront luxurious private rooms nestled amidst coconut farms, each unit is thoughtfully crafted to enhance your stay. The interiors feature modern decor infused with coastal charm, complete with plush bedding, spacious living areas, and state-of-the-art amenities. Floor-to-ceiling windows invite natural light to flood in, offering spectacular views of the Arabian sea. */}
                                  {/* {finalData && finalData.desc22}
                                  </p>
                                 </div>
                                  </div>
                                   </div>
                                   <div className="container mt-5">
                                    <div className="row">
                                      <div className="blog-head5">
                                        <h3> */}
                                          {/* "Culinary Delights" */}
                                          {/* {finalData && finalData.titletwo}
                                          </h3> */}
                                        {/* <p> */}
                                          {/* A stay at Ocean Delight Beach Resort is incomplete without savouring its culinary offerings. The resort’s dining venues are a feast for the senses, providing a menu that celebrates the flavours of the sea and beyond. */}
                                          {/* {finalData && finalData.desc33}
                                        </p>
                                        <div className="col-12">
                                         <div className="blog-img3">
                                          </div>
                                          </div>
                                          <div className="col-12">
                                            <div className="blogDesc3">
                                            <div className="blogtxt3"> */}
                                             {/* <p><span> */}
                                              {/* Open Sea View Restaurant :  {finalData && finalData.titlethree} */}
                                              {/* </span> */}
                                              {/* Known for its fresh seafood and innovative dishes, they offer a fine dining experience with panoramic ocean views. */}
                                              {/* {finalData && finalData.desc4}
                                            </p>  */}
                                            {/* <p> */}
                                              {/* As the name suggests, this beachfront venue is ideal for watching the sun dip below the horizon while enjoying grilled specialties and tropical drinks. */}
                                              {/* {finalData && finalData.descone}
                                              </p>
                                            <p> */}
                                            {/* The resort also offers private dining experiences, where guests can enjoy a romantic candlelight dinner on the beach or an Open Sky Dining experiences with bonfire. */}
                                            {/* {finalData && finalData.desctwo}
                                              </p> 
                                            </div>  
                                            </div>
                                            </div>
                                         <div className="container mt-5">
                                        <div className="row">
                                        <div className="blog-head6">
                                        <h3> */}
                                          {/* "Activities and Adventures" */}
                                          {/* {finalData && finalData.title1}
                                          </h3>
                                        <p> */}
                                          {/* Ocean Delight Beach Resort is a playground for both relaxation and adventure enthusiasts. The resort’s activity calendar is brimming with options to keep guests engaged and entertained. */}
                                          {/* {finalData && finalData.desc22} */}
                                        {/* </p>
                                         </div>
                                        </div>
                                        </div>
                                        <div className="container mt-5">
                                          <div className="row">
                                            <div className="blog-head7">
                                              <h3> */}
                                                {/* "Water Sports and Ocean Exploration" */}
                                                {/* {finalData && finalData.titlefour} */}
                                                {/* </h3> */}
                                              {/* <p><span> */}
                                                {/* The resort’s prime beachfront location makes it a hub for water-based activities. Guests can :  */}
                                                {/* {finalData && finalData.desc44} */}
                                                {/* </span> */}
                                              {/* Snorkel or scuba dive to explore vibrant coral reefs teeming with marine life.  */}
                                              {/* {finalData && finalData.descthree} */}
                                              {/* </p>
                                              <p> */}
                                               {/* Embark on a deep-sea fishing expedition. */}
                                               {/* {finalData && finalData.descfour}
                                                </p> */}
                                              {/* <p> */}
                                                {/* Take deep Scuba lessons with experienced instructors. */}
                                                {/* {finalData && finalData.descfive}
                                                </p>
                                              </div>
                                          </div>
                                         </div>
                                         <div className="container mt-5">
                                          <div className="row">
                                            <div className="blog-head8">
                                              <h3> */}
                                                {/* "Cultural Experiences and Excursions" */}
                                                {/* {finalData && finalData.span2}
                                              </h3>
                                              <p><span> */}
                                                {/* Immerse yourself in the local culture with activities such as: */}
                                                {/* {finalData && finalData.desc5}
                                                </span></p>
                                              <p> */}
                                               {/* Traditional cooking classes, where you can learn to prepare regional delicacies. */}
                                               {/* {finalData && finalData.descsix}
                                              </p>
                                              <p> */}
                                              {/* Guided tours to nearby villages, offering a glimpse into the local way of life. */}
                                              {/* {finalData && finalData.descseven}
                                              </p>
                                              <p> */}
                                             {/* Nightly cultural performances featuring music and dance native to the area. */}
                                             {/* {finalData && finalData.desceight}
                                            </p> */}
                                          {/* <p> */}
                                          {/* For explorers, the resort organizes day trips to nearby attractions such as nature reserves, waterfalls, and historic landmarks. */}
                                          {/* {finalData && finalData.descnine}
                                        </p>    
                                            </div>
                                          </div>
                                          </div>
                                          <div className="container mt-5">
                                            <div className="row">
                                              <div className="blog-head9">
                                                <h3> */}
                                                  {/* "Family-Friendly Fun" */}
                                                  {/* {finalData && finalData.span3}
                                                  </h3> */}
                                                {/* <p> */}
                                                  {/* Ocean Delight Beach Resort is a family-friendly destination with plenty to keep younger guests entertained. Kids and adults can enjoy beach games, swimming, fishing and exploring nearby attractions. The tranquil environment is perfect for bonding, while spacious accommodations and delicious cuisine ensure comfort and enjoyment for the whole family. */}
                                                  {/* {finalData && finalData.desc6}
                                                </p>
                                                </div>
                                            </div>
                                          </div>
                                          <div className="container mt-5">
                                            <div className="row">
                                              <div className="blog-head10">
                                                <h3> */}
                                                  {/* "Planning Your Stay" */}
                                                  {/* {finalData && finalData.span4}
                                                  </h3>
                                                <p> */}
                                                  {/* Getting to Ocean Delight Beach Resort is an adventure in itself. The resort is easily accessible via a boat ride or pickup transfer from the Chipi or Goa airport. Upon arrival, guests are greeted with a refreshing welcome drink and the warm hospitality of the resort staff. */}
                                                  {/* {finalData && finalData.desc7}
                                                </p>
                                                <p><span> */}
                                                  {/* To make the most of your visit, consider the following tips :  */}
                                                  {/* {finalData && finalData.desc8}
                                                  </span></p>
                                                <p><span> */}
                                                  {/* Book Early :  */}
                                                  {/* {finalData && finalData.spanone}
                                                  </span> */}
                                                   {/*  The resort’s popularity means that accommodations fill up quickly, especially during peak seasons. */}
                                                   {/* {finalData && finalData.desc9}
                                                   </p>
                                                <p><span> */}
                                                  {/* Pack Smart :  {finalData && finalData.spantwo} */}
                                                  {/* </span> */}
                                                  {/* Don’t forget essentials like sunscreen, swimwear, and comfortable footwear for exploring. */}
                                                  {/* {finalData && finalData.descten}
                                                  </p>
                                                <p><span> */}
                                                  {/* Customize Your Experience :  */}
                                                  {/* {finalData && finalData.spanthree}
                                                  </span> */}
                                                  {/* Book in advance to take advantage and plan personalized activities and excursions. */}
                                                  {/* {finalData && finalData.descel}
                                                  </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="container mt-5">
                                            <div className="row">
                                              <div className="blog-head11">
                                                <h3> */}
                                                  {/* "Creating Memories to Last a Lifetime" */}
                                                  {/* {finalData && finalData.title2}
                                                  </h3>
                                                <p> */}
                                                  {/* Whether you’re exploring the vibrant underwater world, every moment at Ocean Delight Beach Resort is designed to leave a lasting impression. The combination of natural beauty, exceptional service, and a wide range of experiences makes this destination truly unforgettable. */}
                                                  {/* {finalData && finalData.desc10} */}
                                                {/* </p>
                                                <p> */}
                                                  {/* In conclusion, Ocean Delight Beach Resort is more than just a place to stay – it’s a destination where dreams come to life. Whether you’re seeking relaxation, adventure, or a bit of both, this resort promises an experience that will linger in your heart long after you’ve returned home. So, pack your bags, leave your worries behind, and let the ocean’s delights sweep you away to a world of unparalleled beauty and serenity. */}
                                                  {/* {finalData && finalData.desc11}
                                                </p> */}
                                                
                                                
                                              {/* </div>
                                            </div> */}
                                          {/* // </div> */}
                                           
                                                

 
                                         
                                             
                                        

                                          

            
                                      {/* </div>
                                    </div>
                                   </div>
                                  </div>
                                  </div> */}
    
                    
                   









          {/* </div>
        </div> */}
      </section>

    {/* {
      Blogdata.map((elem,index)=>{
        return(
          <>
<h2></h2>
          <p></p>
          </>
        )
      })
    } */}
      <Footer/>
    </>
  );
};

export default Blog;
